<template>
  <div class="youtube-player">
    <div ref="playerContainer" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: any;
  }
}

const props = defineProps<{
  videoId: string;
}>();

const playerContainer = ref<HTMLDivElement | null>(null);
let player: any = null;

const onYouTubeIframeAPIReady = () => {
  if (playerContainer.value) {
    player = new window.YT.Player(playerContainer.value, {
      videoId: props.videoId,
      height: '100%',
      width: '100%',
    });
  }
};

const loadYouTubeAPI = () => {
  if (window.YT) {
    onYouTubeIframeAPIReady();
  } else {
    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    document.head.appendChild(script);
  }
};

onMounted(() => {
  loadYouTubeAPI();
});

onUnmounted(() => {
  if (player) {
    player.destroy();
  }
});
</script>

<style scoped>
.youtube-player {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}
</style>
